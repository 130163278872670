/**
 * SUBSCIPTION-EDIT-TYPE.WIDGET
 * Chip where you can change the type of subscription for the customers
 * */
import { WithTranslation, withTranslation } from "react-i18next"
import Chip from "@/components/chip"
import { useState } from "react"
import Modal from "@/components/modal"
import CardContainer from "@/components/card-container"
import CardButton from "@/components/card-button"
import { Account, ACCOUNT_CS_MOODS, ACCOUNT_CS_TYPES, AccountState } from "@/redux/account.types"
import { store } from ".."
import { accountActivate, accountEdit, accountStatus, accountUpdate_AsSupervisor } from "@/redux/account.actions"
import { connect } from "react-redux"
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types"
import LoadingModal from "@/modals/loading.modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface OwnProps {
  currentAccount: Account
}

interface StateProps {
  account: AccountState
  onEdit?: Function
}

type Props = OwnProps & StateProps & WithTranslation

const MODAL_EDIT_MOOD = "MODAL_EDIT_MOOD"
const MODAL_EDIT_TYPE = "MODAL_EDIT_TYPE"

function AccountEditCsWidget(props: Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentAccount, setCurrentAccount] = useState(new Account(props.currentAccount))

  const [csAccountTypes] = useState(ACCOUNT_CS_TYPES.map((x) => new Account({ csType: x })).concat([new Account()]))

  async function closeModal() {
    store.dispatch(accountStatus(STATUS_SAVING))
    const response: any = await store.dispatch(accountUpdate_AsSupervisor(props.account.active))
    store.dispatch(accountStatus(STATUS_SAVED))
    if (!response.error) {
      setCurrentAccount(props.account.active)
      setCurrentModal(null)
      if (props.onEdit) {
        props.onEdit(props.account.active)
      }
    }
  }

  function getMoodColor(value: number | null) {
    switch (value) {
      case 1:
        return "#eb5a46"
      case 2:
        return "#20CA7E"
      case 3:
        return "#1CB06E"
      default:
        return "#e8e8e8"
    }
  }

  function openModal(modal: string) {
    store.dispatch(accountActivate(currentAccount))
    setCurrentModal(modal)
  }

  return (
    <div className="flex">
      {currentModal === MODAL_EDIT_TYPE && (
        <Modal onClose={() => closeModal()} isCloseButtonVisible>
          {props.account.status === STATUS_SAVING && <LoadingModal />}
          <CardContainer>
            {csAccountTypes.map((item, i) => (
              <CardButton
                key={i}
                isActive={props.account.active.csType === item.csType}
                iconColor={item.csTypeColor ? item.csTypeColor : "#CCCCCC"}
                icon={item.csTypeIcon}
                title={t("account_cs_" + item.csType)}
                onClick={
                  () => store.dispatch(accountEdit("csType", item.csType)) /*setCurrentType(csType.type*/
                }></CardButton>
            ))}
          </CardContainer>
          <p>{t("account_cs_" + props.account.active.csType + "_help")}</p>
        </Modal>
      )}

      {currentModal === MODAL_EDIT_MOOD && (
        <Modal onClose={() => closeModal()} isCloseButtonVisible>
          {props.account.status === STATUS_SAVING && <LoadingModal />}
          <CardContainer>
            {ACCOUNT_CS_MOODS.map((mood) => (
              <CardButton
                key={mood.value}
                isActive={props.account.active.csMood === mood.value}
                iconColor={getMoodColor(mood.value)}
                icon={mood.icon}
                title={t("account_cs_mood_" + mood.value)}
                onClick={
                  () => store.dispatch(accountEdit("csMood", mood.value)) /*setCurrentType(csType.type*/
                }></CardButton>
            ))}
          </CardContainer>
        </Modal>
      )}

      <Chip
        icon={currentAccount.csTypeIcon}
        onClick={() => openModal(MODAL_EDIT_TYPE)}
        color={currentAccount.csTypeColor}>
        {t("account_cs_" + currentAccount.csType)}
      </Chip>

      <div className="width-20" />

      <div className="flex _hover" onClick={() => openModal(MODAL_EDIT_MOOD)}>
        {ACCOUNT_CS_MOODS.map((mood) => (
          <FontAwesomeIcon
            key={mood.value}
            icon={mood.icon}
            className="_hover"
            style={{
              fontSize: 16,
              color: getMoodColor(currentAccount.csMood === mood.value ? currentAccount.csMood : null),
              margin: 2
            }}
          />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  account: state.account
})

export default connect(mapStateToProps)(withTranslation()(AccountEditCsWidget))
