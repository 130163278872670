/**
 * DASHBOARD-NPS-NOTE
 * Display the score NPS
 */

import { withTranslation, WithTranslation } from "react-i18next"
import DashboardNumber from "@/components/dashboard-number"
import { faFaceMeh, faFrown, faSmile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { QUESTION_NPS_CEIL_BAD, QUESTION_NPS_CEIL_GOOD } from "@/redux/question.types"

interface OwnProps {
  npsNote: number
  isSmall?: boolean
  isLoading?: boolean
  fontSize?: number
}

type Props = OwnProps & WithTranslation

function DashboardNpsNoteWidget(props: Props) {
  const { t } = props

  function getStyle() {
    let color = "#eb5a46"
    let icon = faFrown

    if (props.npsNote >= QUESTION_NPS_CEIL_GOOD) {
      color = "#20CA7E"
      icon = faSmile
    } else if (props.npsNote >= QUESTION_NPS_CEIL_BAD) {
      color = "#f2d600"
      icon = faFaceMeh
    }

    return {
      color,
      icon
    }
  }

  return props.isSmall ? (
    <div style={{ color: getStyle().color, fontSize: 16 }}>
      <FontAwesomeIcon icon={getStyle().icon} />
      &nbsp;
      <span>{Number(props.npsNote.toFixed(0))}</span>
    </div>
  ) : (
    <DashboardNumber
      color={getStyle().color}
      count={props.npsNote.toFixed(0)}
      icon={getStyle().icon}
      header={t("nps_header")}
      title={t("nps")}
      isLoading={props.isLoading}
      fontSize={props.fontSize}
    />
  )
}

export default withTranslation()(DashboardNpsNoteWidget)
