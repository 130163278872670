/**
 * DASHBOARD-NPS.MINI
 * NPS score for the survey
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { FilterState } from "@/redux/filter.types"
import { Session } from "@/redux/_session.types"
import { store } from "@/index"
import { SurveyState } from "@/redux/survey.types"
import Card from "@/components/card"
import DashboardNumber from "@/components/dashboard-number"
import { fetchNps } from "@/redux/nps.actions"
import { sortBy } from "lodash"
import { NavigateFunction, useNavigate } from "react-router-dom"
import DashboardNpsNoteWidget from "./dashboard-nps-note.widget"

interface StateProps extends WithTranslation {
  _session: Session
  filter: FilterState
  survey: SurveyState
}

function DashboardNpsMiniWidget(props: StateProps) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [nps, setNps] = useState(0)
  const [showData, setShowData] = useState(props._session.modules.nps)

  //Load count of message every times filters are changed
  //All level should be greater than 2
  useEffect(() => {
    async function load() {
      setIsLoading(true)

      if (!props._session.modules.nps || props.survey.active.randomData) {
        setNps(0)
        setShowData(false)
      } else {
        const response: any = await store.dispatch(fetchNps(props.filter.dashboard))
        if (response.error) {
          setShowData(false)
        } else {
          if (!response.length) {
            setShowData(false)
          } else {
            const npsQuestion = sortBy(response, "aid")[0]
            setNps(npsQuestion.nps)
            setShowData(true)
          }
        }
      }

      setIsLoading(false)
    }

    load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props._session.modules.nps, props.survey.active.randomData, props.filter.dashboard])

  return (
    <Card className="_hover flex1" isWithoutPadding onClick={() => navigate("/dashboard/nps")}>
      {showData ? (
        <DashboardNpsNoteWidget isLoading={isLoading} npsNote={nps} />
      ) : (
        <DashboardNumber
          isInactive
          count={t("utils_unavailable")}
          icon={faExclamationTriangle}
          header={t("nps_header")}
          title={t("nps")}
          fontSize={12}
        />
      )}
    </Card>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardNpsMiniWidget))
