/**
 * REPORT-SCREENSHOT-QUESTIONS.WIDGET.TSX
 * Preview of the slide
 */
import { connect } from "react-redux"
import { WithTranslation, withTranslation } from "react-i18next"
import { Session } from "@/redux/_session.types"
import { Topic, TopicState } from "@/redux/topic.types"
import { useState } from "react"
import { flatMap } from "lodash"
import { Question } from "@/redux/question.types"
import { Page } from "@/redux/page.types"

interface StateProps {
  _session: Session
  topic: TopicState
}

interface OwnProps {
  isFullWidth: boolean
  currentPage: Page
  currentTopic: Topic
}

type Props = StateProps & OwnProps & WithTranslation

function ReportScreenshotMessagesWidget(props: Props) {
  const { t } = props

  const [question] = useState<Question>(initQuestions())

  function initQuestions() {
    let questions =
      props.currentTopic.Questions.length > 0
        ? props.currentTopic.Questions
        : flatMap(props.topic.list, (x) => x.Questions)
    questions = questions.filter((x) => x.type === "open")
    return questions.length > 0 ? questions[0] : new Question()
  }

  return (
    <div>
      <div
        style={{
          fontSize: 18,
          marginBottom: 24
        }}>
        <b>{t("page_type_screenshot_messages_summarize_help", { question: question.label })}</b>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(ReportScreenshotMessagesWidget))
