/**
 * NPS.ACTIONS
 * Route for net promoter score calculation
 */

export const benchmarkCalculateNps_AsSupervisor = () => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/benchmark/calculate-nps"
  }
})

export const benchmarkFetchByAccount_AsSupervisor = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/supervisor/benchmark/accounts"
  }
})

export const benchmarkFetchNps = (participation: number) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/benchmark/nps",
    data: {
      participation
    }
  }
})

export const benchmarkUpdateAccount_AsSupervisor = (id: string, key: "sector" | "size", value: string | null) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/benchmark/account",
    data: {
      id,
      key,
      value
    }
  }
})
