/**
 * DASHBOARD-NPS
 * NPS score for the survey
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useEffect, useState } from "react"
import { FilterState } from "@/redux/filter.types"
import { Session } from "@/redux/_session.types"
import { store } from "@/index"
import { SurveyState } from "@/redux/survey.types"
import { fetchNps } from "@/redux/nps.actions"
import { Topic, TopicState } from "@/redux/topic.types"
import { Question, QUESTION_NPS_CEIL_BAD, QUESTION_NPS_CEIL_GOOD, QuestionNps } from "@/redux/question.types"
import PageLoader from "@/components/page-loader"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import Card from "@/components/card"
import { flatten, sortBy } from "lodash"
import DashboardNumber from "@/components/dashboard-number"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import DashboardRepartitionChartWidget from "./dashboard-repartition-chart.widget"
import Space from "@/components/space"
import DashboardNpsMeterWidget from "./dashboard-nps-meter.widget"
import DashboardNpsHeatmapWidget from "./dashboard-nps-heatmap.widget"
import DashboardNpsNoteWidget from "./dashboard-nps-note.widget"
import DashboardNpsBenchmarksWidget from "./dashboard-nps-benchmarks.widget"
import Modal from "@/components/modal"

interface StateProps extends WithTranslation {
  _session: Session
  filter: FilterState
  survey: SurveyState
  topic: TopicState
}

interface OwnProps {
  isOverview?: boolean
  isSmall?: boolean
}

type Props = StateProps & OwnProps

const MODAL_HELP = "MODAL_HELP"

function DashboardNpsWidget(props: Props) {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [currentModal, setCurrentModal] = useState<null | string>(null)
  const [npsQuestions, setNpsQuestions] = useState<QuestionNps[]>([])

  //Load count of message every times filters are changed
  //All level should be greater than 2
  useEffect(() => {
    async function load() {
      setIsLoading(true)

      if (props._session.modules.nps && props.survey.active.id.length > 0) {
        const response: any = await store.dispatch(fetchNps(props.filter.dashboard))

        if (response.error || response.length < 1) {
          setNpsQuestions([])
        } else {
          setNpsQuestions(sortBy(response, "nps"))
        }
      }

      setIsLoading(false)
    }

    load()
  }, [props.survey.active.id, props._session.modules.nps, props.survey.active.randomData, props.filter.dashboard])

  function getQuestion(questionAid) {
    const questions = flatten(props.topic.list.map((x) => x.Questions))
    const question = questions.find((x) => x.aid === questionAid)
    return new Question(question)
  }

  function getTopic(questionAid) {
    const topic = props.topic.list.find((x) => x.Questions.map((q) => q.aid).indexOf(questionAid) > -1)
    return new Topic(topic)
  }

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      {currentModal === MODAL_HELP && (
        <Modal onClose={() => setCurrentModal(null)} isCloseButtonVisible title={t("nps_help_1")}>
          <p>{t("nps_help_2")}</p>

          <ul>
            <li>{t("nps_help_detractors")}</li>
            <li>{t("nps_help_neutral")}</li>
            <li>{t("nps_help_promotors")}</li>
          </ul>
        </Modal>
      )}

      {props.survey.active.randomData ? (
        <p className="grey-t">
          <b>{t("utils_unavailable")}</b>
        </p>
      ) : (
        <div>
          {!props.isOverview && (
            <b className="grey-t">
              {t("nps_explanation")}{" "}
              <span className="_hover" onClick={() => setCurrentModal(MODAL_HELP)}>
                <u>{t("nps_help_1")}</u>
              </span>
            </b>
          )}
          {!props.isOverview && <div className="height-20" />}

          {!npsQuestions.length && (
            <p className="grey-t">
              <b>{t("nps_empty")}</b>
            </p>
          )}

          {npsQuestions.map((question: QuestionNps) => (
            <div key={question.aid} className="flex" style={{ width: 1000 }}>
              <Card className="flex1" exportName={getQuestion(question.aid).label}>
                <div
                  style={{
                    width: props.isSmall ? 300 : undefined,
                    marginTop: props.isSmall ? -40 : undefined
                  }}>
                  <p>
                    <b>{getTopic(question.aid).label}</b>
                  </p>
                  <p>{getQuestion(question.aid).label}</p>
                </div>

                <div className="height-20" />

                <div className="flex">
                  <div className="flex flex-dcol" style={{ minHeight: 230, marginTop: -20 }}>
                    <DashboardNpsNoteWidget npsNote={question.nps} fontSize={48} />

                    <div className="flex">
                      <Space />

                      <DashboardNpsMeterWidget
                        ceilBad={QUESTION_NPS_CEIL_BAD}
                        ceilGood={QUESTION_NPS_CEIL_GOOD}
                        score={question.nps}
                      />

                      <Space />
                    </div>

                    <Space />
                  </div>

                  <Space />

                  {!props.isSmall && <DashboardRepartitionChartWidget questionNpsAid={question.aid} />}

                  <Space />

                  {!props.isSmall && (
                    <div className="flex flex-dcol">
                      <Space />

                      {!props.isOverview && (
                        <DashboardNumber
                          isLoading={isLoading}
                          count={question.total}
                          icon={faUser}
                          header={t("nps_users_header")}
                          title={t("nps_users")}
                        />
                      )}

                      <Space />
                    </div>
                  )}
                </div>

                <DashboardNpsHeatmapWidget questionAid={question.aid} />
              </Card>

              <div className="width-20" />

              <DashboardNpsBenchmarksWidget />
            </div>
          ))}
        </div>
      )}
    </PageLoader>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(DashboardNpsWidget))
