/**
 * DASHBOARD-NUMBER
 * Display number for dashboard widget
 */
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { faCircleNotch, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps {
  _session: Session
}

interface OwnProps {
  color?: string
  count: any
  fontSize?: number
  header?: string
  icon?: IconDefinition
  isActive?: boolean
  isInactive?: boolean
  isLoading?: boolean
  onInfo?: Function
  title?: string
  tooltip?: string
}

type Props = StateProps & OwnProps

function DashboardNumber(props: Props) {
  function clickInfo() {
    if (props.onInfo) {
      props.onInfo()
    }
  }

  function getClassName() {
    let className = "dashboard-number-count"

    if (props.isInactive) {
      className += " medgrey-t"
    } else {
      className += " grey-t"
    }

    return className
  }

  function getStyle() {
    let color
    let fontSize

    if (props.color && !props.isInactive) {
      color = props.color
    } else if (props.isActive) {
      color = props._session.accountColors.active
    }

    if (props.fontSize) {
      fontSize = props.fontSize
    }

    return {
      color,
      fontSize
    }
  }

  return (
    <div className="flex1 dashboard-number">
      <div>{props.header}</div>

      <div>
        <b>{props.title}</b>
        {props.onInfo && (
          <span className="grey-t dashboard-number-info">
            &nbsp;
            <FontAwesomeIcon
              icon={faInfoCircle}
              data-tip={props.tooltip ? props.tooltip.replaceAll("**", "") : undefined}
              onClick={clickInfo}
            />
          </span>
        )}
      </div>

      {props.isLoading ? (
        <div className="dashboard-number-count grey-t">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : (
        <div className={getClassName()} style={getStyle()}>
          {props.icon && <FontAwesomeIcon icon={props.icon} />}
          {props.icon ? props.count : <b>{isNaN(Number(props.count)) ? props.count : Number(props.count)}</b>}
        </div>
      )}

      <ReactTooltip />
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(DashboardNumber)
