/**
 * TOPIC.ACTIONS
 * Card of questionnaires
 */

import {
  TOPIC_ADD,
  TOPIC_REMOVE,
  TOPIC_EDIT,
  TOPIC_EDIT_NAME,
  TOPIC_EDIT_QUESTION,
  TOPIC_ACTIVATE,
  TOPIC_GET,
  TOPIC_INIT,
  TOPIC_STATUS,
  TOPIC_ADD_QUESTION,
  TOPIC_EDIT_AXES,
  TOPIC_REMOVE_AXES,
  TOPIC_EDIT_PROPOSITION,
  TOPIC_REMOVE_PROPOSITION,
  TOPIC_ADD_PROPOSITION,
  TOPIC_REPLACE_QUESTION,
  TOPIC_EDIT_TRIGGER,
  TOPIC_REMOVE_QUESTION,
  TOPIC_SWAP,
  TOPIC_SAVE,
  TOPIC_SWAP_PROPOSITION,
  TOPIC_COPY_QUESTION,
  TOPIC_COPY,
  Topic,
  TOPIC_EDIT_ANNOTATIONS,
  TOPIC_EDIT_DEFAULT_SCALE_COUNT,
  TOPIC_EDIT_DEFAULT_SCALE_NO_OPINION
} from "./topic.types"
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING
} from "./_status.types"
import { v4 as uuid } from "uuid"
import { templateActivate, templateStatus } from "./template.actions"
import { Template } from "./template.types"
import fetchChoiceMessages from "@/utils/fetch-choice-messages.utils"
import { Axis } from "./axis.types"
import { Page } from "./page.types"
import { store } from "@/index"
import { Question } from "./question.types"

export const topicActivate = (topic) => ({
  type: TOPIC_ACTIVATE,
  payload: {
    topic
  }
})

export const topicAdd = (
  fromSupervisor: boolean,
  axis: Axis | null,
  addAtTheEnd: boolean,
  isAtStart: boolean,
  isDraft: boolean
) => ({
  type: TOPIC_ADD,
  payload: {
    fromSupervisor,
    axis,
    addAtTheEnd,
    isAtStart,
    isDraft
  }
})

export const topicAddProposition = (questionId) => ({
  type: TOPIC_ADD_PROPOSITION,
  payload: {
    questionId
  }
})

export const topicAddQuestion = (fromSupervisor, isPrimary, triggerValue) => ({
  type: TOPIC_ADD_QUESTION,
  payload: {
    fromSupervisor,
    isPrimary,
    triggerValue
  }
})

export const topicCopy = (topic, axis) => ({
  type: TOPIC_COPY,
  payload: {
    axis,
    topic
  }
})

export const topicCopyQuestion = (topicId, question) => ({
  type: TOPIC_COPY_QUESTION,
  payload: {
    topicId,
    question
  }
})

export const topicEdit = (key, value) => ({
  type: TOPIC_EDIT,
  payload: {
    key,
    value
  }
})

export const topicEditAnnotations = (annotations) => ({
  type: TOPIC_EDIT_ANNOTATIONS,
  payload: {
    annotations
  }
})

export const topicEditAxes = (axis) => ({
  type: TOPIC_EDIT_AXES,
  payload: {
    axis
  }
})

export const topicEditDefaultScaleCount = (defaultScaleCount: number) => ({
  type: TOPIC_EDIT_DEFAULT_SCALE_COUNT,
  payload: {
    defaultScaleCount
  }
})

export const topicEditDefaultScaleNoOpinion = (disableNoOpinion: boolean) => ({
  type: TOPIC_EDIT_DEFAULT_SCALE_NO_OPINION,
  payload: {
    disableNoOpinion
  }
})

export const topicEditName = (language, value) => ({
  type: TOPIC_EDIT_NAME,
  payload: {
    language,
    value
  }
})

export const topicEditProposition = (propositionId, questionId, key, value) => ({
  type: TOPIC_EDIT_PROPOSITION,
  payload: {
    propositionId,
    questionId,
    key,
    value
  }
})

export const topicEditQuestion = (questionId, key, value) => ({
  type: TOPIC_EDIT_QUESTION,
  payload: {
    questionId,
    key,
    value
  }
})

export const topicEditTrigger = (questionId, key, value) => ({
  type: TOPIC_EDIT_TRIGGER,
  payload: {
    questionId,
    key,
    value
  }
})

export const topicGet = (topics) => ({
  type: TOPIC_GET,
  payload: {
    topics
  }
})

export const topicInit = () => ({
  type: TOPIC_INIT
})

export const topicRemove = (id) => ({
  type: TOPIC_REMOVE,
  payload: {
    id
  }
})

export const topicRemoveAxes = (axisId) => ({
  type: TOPIC_REMOVE_AXES,
  payload: {
    axisId
  }
})

export const topicRemoveProposition = (questionId, propositionId) => ({
  type: TOPIC_REMOVE_PROPOSITION,
  payload: {
    questionId,
    propositionId
  }
})

export const topicRemoveQuestion = (questionId, topicId) => ({
  type: TOPIC_REMOVE_QUESTION,
  payload: {
    questionId,
    topicId
  }
})

export const topicReplaceQuestion = (question) => ({
  type: TOPIC_REPLACE_QUESTION,
  payload: {
    question
  }
})

export const topicSave = () => ({
  type: TOPIC_SAVE
})

export const topicStatus = (status) => ({
  type: TOPIC_STATUS,
  payload: {
    status
  }
})

export const topicSwap = (sourceId, destinationId) => ({
  type: TOPIC_SWAP,
  payload: {
    sourceId,
    destinationId
  }
})

export const topicSwapProposition = (questionId, sourceId, destinationId) => ({
  type: TOPIC_SWAP_PROPOSITION,
  payload: {
    questionId,
    sourceId,
    destinationId
  }
})

//API

export const topicFetch = (templateId) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/topics",
    data: {
      templateId
    }
  }
})

export const topicFetchBySurveys = (surveyIds) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/topic/surveys",
    data: {
      surveyIds
    }
  }
})

export const topicFetchOne = (topicId) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/topic",
    data: {
      topicId
    }
  }
})

export const topicFetchPublic = (templateId) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/public/topics",
    data: {
      templateId
    }
  }
})

export const topicUpdate = (template, topicsUpdated, topicsDeleted) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/topics",
    data: {
      template,
      topicsUpdated,
      topicsDeleted
    }
  }
})

export const topicUpdate_AsSupervisor = (template, topicsUpdated, topicsDeleted) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/topics",
    data: {
      template,
      topicsUpdated,
      topicsDeleted
    }
  }
})

//THUNK

//Set topic of the question when change topic for an existing question
export const topicAddAndCopyQuestion: any = (fromSupervisor, axis, question) => async (dispatch, getState) => {
  dispatch(topicAdd(fromSupervisor, axis, false, false, true))
  dispatch(topicEdit("Questions", []))
  dispatch(topicCopyQuestion(getState().topic.active.id, question))
}

//Add from search
export const topicAddAndReplaceQuestion: any = (fromSupervisor, question) => async (dispatch, getState) => {
  dispatch(topicAdd(fromSupervisor, getState().topic.active.Axis, true, false, true))
  dispatch(topicReplaceQuestion(question))
}

//Add a new topic from imported question
//Create topic and copy question into this topic
export const topicCopyAndCopyQuestion: any = (question) => async (dispatch) => {
  if (question.Topics.length) {
    const topic = new Topic({
      id: uuid(),
      name: question.Topics[0].name
    })

    question.id = uuid()
    question.fromSupervisor = false

    dispatch(topicCopy(topic, null))
    dispatch(topicCopyQuestion(topic.id, question))
  }
}

//Get topics for the database
//Load Function depends if user is in admin interface, customer interface or public interface
export const topicFetchAndGet: any = (duplicateTemplate: Template | null) => async (dispatch, getState) => {
  if (duplicateTemplate) {
    dispatch(templateActivate(duplicateTemplate))
  }

  dispatch(topicGet([]))

  let loadFunction: Function
  if (getState()._session.authLevel === 0 || getState()._session.interfaceType === "SUPERVISOR") {
    loadFunction = topicFetchPublic
  } else {
    loadFunction = topicFetch
  }

  dispatch(topicStatus(STATUS_LOADING))
  const response: any = await dispatch(loadFunction(getState().template.active.id))
  dispatch(topicStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED))

  if (response.error) {
    dispatch(topicGet([]))
  } else {
    dispatch(topicGet(response))
    if (duplicateTemplate) {
      setTimeout(() => {
        window.location.href = "/template/editor?duplicate=true"
      }, 200)
    }
  }
}

//Open topic and load questions choice
export const topicOpen: any = (topic: Topic) => async (dispatch, getState) => {
  dispatch(topicActivate(topic))

  if (!topic.open) {
    //Update date (for bookmark)
    dispatch(topicEdit("dateOpen", new Date()))

    //Fetch messages for choice question
    fetchChoiceMessages(topic, getState().filter.dashboard)
  }

  dispatch(topicEdit("open", !topic.open))
}

//Save all modification
//> Init template object with ordre of topics to save
//> Detect saved function (depends if user is connected in supervisor interface)
export const topicUpdateAndSave: any = () => async (dispatch, getState) => {
  const template = {
    id: getState().template.active.id,
    topicsOrder: getState().topic.list.map((x) => x.id),
    axesOrder: getState().axis.list.map((x) => x.id),
    options: getState().template.active.options
  }

  const topicsUpdated = getState().topic.list.filter((x) => x.updated)
  const topicsDeleted = getState().topic.listDeleted.concat(
    getState()
      .topic.list.filter((x) => !x.Questions.length)
      .map((x) => x.id)
  )

  dispatch(templateStatus(STATUS_SAVING))
  const updateFunction = getState()._session.interfaceType === "SUPERVISOR" ? topicUpdate_AsSupervisor : topicUpdate
  const response: any = await dispatch(updateFunction(template, topicsUpdated, topicsDeleted))

  if (response.error) {
    dispatch(templateStatus(STATUS_SAVE_ERROR))
  } else {
    dispatch(templateStatus(STATUS_SAVED))
    dispatch(topicSave())
  }
}

// Compte le nombre total de lignes utilisées par un topic
export function topicCountReportLines(topic: Topic) {
  let linesCount = 0
  topicFilterReportQuestions(topic.Questions).forEach((question) => {
    linesCount += 1 // La ligne pour la Question
    linesCount += question.Propositions.length / 5 // Une ligne pour chaque Proposition (0.25)
  })
  return linesCount
}

//Remove questions for the report
//Hide without participation
export function topicFilterReportQuestions(questions: Question[]) {
  return questions.filter((x) => x.type !== "open")
}

//Group all topics for the report
export function topicGroupForReport(page: Page) {
  const maxLines: number = page.twoColumns ? 7 : 10
  const groupedTopics: Topic[][] = []
  let currentGroup: Topic[] = []
  let currentLinesCount = 0

  store.getState().topic.list.forEach((topic) => {
    if (new Page(page).contentLeft.screenshot.isTopicDisplayed(topic)) {
      const topicLines = topicCountReportLines(topic)

      // Vérifier si l'ajout du Topic actuel dépasse la limite de lignes
      if (currentLinesCount + topicLines > maxLines) {
        // Si oui, ajouter le groupe actuel à la liste des groupes et démarrer un nouveau groupe
        groupedTopics.push(currentGroup)
        currentGroup = [topic]
        currentLinesCount = topicLines
      } else {
        // Sinon, ajouter le Topic actuel au groupe actuel
        currentGroup.push(topic)
        currentLinesCount += topicLines
      }
    }
  })

  // Ajouter le dernier groupe à la liste des groupes
  if (currentGroup.length > 0) {
    groupedTopics.push(currentGroup)
  }

  return groupedTopics
}
