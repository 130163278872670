/**
 * DASHBOARD-NPS-BENCHMARK-ITEM
 * Chat for displaying specific benchmark (for sector or size)
 */

import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { BENCHMARK_SECTOR_LIST, BENCHMARK_SIZE_LIST, BenchmarkItem } from "@/redux/benchmark.types"
import DashboardNpsMeterWidget from "./dashboard-nps-meter.widget"
import { QUESTION_NPS_CEIL_BAD, QUESTION_NPS_CEIL_GOOD } from "@/redux/question.types"
import DashboardNpsNoteWidget from "./dashboard-nps-note.widget"
import ListDropdown from "@/components/list-dropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"

interface OwnProps extends WithTranslation {
  benchmarkCategory: "size" | "sector"
  benchmarkItem?: BenchmarkItem
  onChange: Function
}

function DashboardNpsBenchmarkItemWidget(props: OwnProps) {
  const { t } = props

  const [isLoading, setIsLoading] = useState(false)
  const [values] = useState(props.benchmarkCategory === "sector" ? BENCHMARK_SECTOR_LIST : BENCHMARK_SIZE_LIST)

  //Fake loader
  //Reload the nps note widget
  function changeValue(id: string) {
    setIsLoading(true)
    props.onChange(id)
    setTimeout(() => {
      setIsLoading(false)
    }, 250)
  }

  return (
    <div style={{ textAlign: "center", marginBottom: 28, marginTop: 8 }}>
      <p>{t("benchmark_title_" + props.benchmarkCategory)}</p>

      <ListDropdown
        isNullAllowed
        isBold
        active={props.benchmarkCategory === "size" ? props.benchmarkItem?.size : props.benchmarkItem?.sector}
        isCaretAlwaysVisible
        onSelect={(e) => changeValue(e.id)}
        values={values.map((x) => {
          return { id: x, name: t("benchmark_" + props.benchmarkCategory + "_" + x) }
        })}
        value={
          props.benchmarkItem
            ? t(
                "benchmark_" +
                  props.benchmarkCategory +
                  "_" +
                  (props.benchmarkCategory === "size" ? props.benchmarkItem.size : props.benchmarkItem.sector)
              )
            : null
        }
      />

      {props.benchmarkItem && (
        <div>
          {props.benchmarkCategory === "size" && (
            <p style={{ fontSize: 12 }}>
              {t("benchmark_sector_for_size", { sector: t("benchmark_sector_" + props.benchmarkItem.sector) })}
            </p>
          )}

          <div style={{ height: 52, marginTop: 14, paddingLeft: 90 }} className="flex">
            {isLoading ? (
              <div
                style={{
                  width: 74,
                  textAlign: "center",
                  fontSize: 22
                }}>
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <DashboardNpsMeterWidget
                isSmall
                ceilBad={QUESTION_NPS_CEIL_BAD}
                ceilGood={QUESTION_NPS_CEIL_GOOD}
                score={Number(props.benchmarkItem.value)}
              />
            )}
          </div>

          <DashboardNpsNoteWidget isSmall npsNote={Number(props.benchmarkItem.value)} />
        </div>
      )}
    </div>
  )
}

export default withTranslation()(DashboardNpsBenchmarkItemWidget)
