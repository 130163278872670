/**
 * SUPERVISOR.BENCHMARK
 * Update benchmark data
 */

import PageHeader from "@/components/page-header"
import NavSupervisor from "@/nav/nav.supervisor"
import SupervisorBenchmarkWidget from "@/widgets/supervisor-benchmark.widget"

function SupervisorBenchmarkRoute() {
  return (
    <NavSupervisor>
      <PageHeader title="Benchmark"></PageHeader>
      <SupervisorBenchmarkWidget />
    </NavSupervisor>
  )
}

export default SupervisorBenchmarkRoute
