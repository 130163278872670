/**
 * DASHBOARD-NPS-TRENDS
 * NPS score for the survey
 */

import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { store } from ".."
import { benchmarkFetchNps } from "@/redux/benchmark.actions"
import { connect } from "react-redux"
import { SurveyState } from "@/redux/survey.types"
import PageLoader from "@/components/page-loader"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import { BenchmarkItem } from "@/redux/benchmark.types"
import Space from "@/components/space"
import DashboardNpsBenchmarkItemWidget from "./dashboard-nps-benchmark-item.widget"
import { toast } from "react-toastify"

interface StateProps extends WithTranslation {
  survey: SurveyState
}

type Props = StateProps

function DashboardNpsBenchmarksWidget(props: Props) {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [sector, setSector] = useState<BenchmarkItem | undefined>(undefined)
  const [size, setSize] = useState<BenchmarkItem | undefined>(undefined)
  const [benchmarks, setBenchmarks] = useState<BenchmarkItem[]>([])

  useEffect(() => {
    loadData()
    async function loadData() {
      const response: any = await store.dispatch(benchmarkFetchNps(props.survey.active.participationSend))

      if (!response.error) {
        setBenchmarks(response.benchmarks)
        setSector(response.benchmarks.find((x) => x.sector === response.sector && !x.size))
        setSize(response.benchmarks.find((x) => x.sector === response.sector && x.size === response.size))
      }

      setIsLoading(false)
    }
  }, [])

  function changeSector(sectorId: string | null) {
    setSector(benchmarks.find((x) => x.sector === sectorId && !x.size))
    setSize(undefined)
  }

  function changeSize(sizeId: string | null) {
    const value = benchmarks.find((x) => x.sector === sector?.sector && x.size === sizeId)
    if (!sizeId) {
      setSize(undefined)
    } else if (!value && sizeId) {
      toast(
        t("benchmark_empty", {
          sector: t("benchmark_sector_" + sector?.sector),
          size: t("benchmark_size_" + sizeId)
        })
      )
    } else {
      setSize(value)
    }
  }

  return (
    <div className="grey-t" style={{ width: 260 }}>
      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <div className="flex">
          <Space />
          <h2>{t("benchmark_title")}</h2>
          <div className="width-10" />
          <img
            src={require("@/assets/assistant/owl_female_face.png")}
            alt={"owl assistant"}
            width={40}
            style={{ objectFit: "contain" }}
          />
          <Space />
        </div>

        <DashboardNpsBenchmarkItemWidget
          benchmarkCategory="sector"
          benchmarkItem={sector}
          onChange={(sectorId) => changeSector(sectorId)}
        />

        {sector && (
          <DashboardNpsBenchmarkItemWidget
            benchmarkCategory="size"
            benchmarkItem={size}
            onChange={(sizeId) => changeSize(sizeId)}
          />
        )}
      </PageLoader>
    </div>
  )
}

const mapStateToProps = (state) => ({
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardNpsBenchmarksWidget))
