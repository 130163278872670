/**
 * BENCHMARK.TYPES
 * List of type for the benchmark
 */

export interface BenchmarkItem {
  id: string
  type: string
  value: number
  sector: string
  size: string
}

export const BENCHMARK_SECTOR_LIST = [
  "tech",
  "commerce",
  "industry",
  "healthcare",
  "education",
  "services",
  "media",
  "public",
  "associations",
  "housing"
]

export const BENCHMARK_SIZE_LIST = ["0_200", "200_1000", "1000+"]

export const BENCHMARK_CATEGORY_TYPES = ["sector", "size"]
