/**
 * SUPERVISOR.SUBSCRIPTIONS
 * Manage current subscriptions
 */

import { WithTranslation, withTranslation } from "react-i18next"
import PageHeader from "@/components/page-header"
import NavSupervisor from "@/nav/nav.supervisor"
import SubscriptionsListWidget from "@/widgets/subscriptions-list.widget"

function SupervisorSubscriptionsRoute(props: WithTranslation) {
  const { t } = props
  return (
    <NavSupervisor>
      <PageHeader title={t("subscriptions")}></PageHeader>
      <SubscriptionsListWidget title="Projets en cours" />
    </NavSupervisor>
  )
}

export default withTranslation()(SupervisorSubscriptionsRoute)
