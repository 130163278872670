/**
 * DASHBOARD-NPS-HEATMAP
 * NPS score by populations
 */

import PageLoader from "@/components/page-loader"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { store } from ".."
import { fetchNpsHeatmap } from "@/redux/nps.actions"
import { connect } from "react-redux"
import { FilterState } from "@/redux/filter.types"
import { Session } from "@/redux/_session.types"
import Space from "@/components/space"
import initFilters from "@/utils/init-filters.utils"
import getFilterName from "@/utils/get-filter-name.utils"
import DashboardNpsNoteWidget from "./dashboard-nps-note.widget"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import ListItem from "@/components/list-item"
import ListDropdown from "@/components/list-dropdown"
import Tooltip from "@/components/tooltip"
import getHeatmapCeils from "@/utils/get-heatmap-ceils.utils"
import getUserFilterDates from "@/utils/get-user-filter-dates.utils"
import { SurveyState } from "@/redux/survey.types"

interface StateProps {
  _session: Session
  filter: FilterState
  survey: SurveyState
}

interface OwnProps {
  questionAid: number
}

interface NpsHeatmapRow {
  name: string
  nps: number
  total: number
  repartition: number[]
}

type Props = StateProps & OwnProps & WithTranslation

function DashboardNpsHeatmapWidget(props: Props) {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [filterName, setFilterName] = useState<string | null>(props._session.accountOptions.heatmapFilterName)
  const [rows, setRows] = useState<NpsHeatmapRow[]>([])
  const [repartitionWidthRatio, setRepartitionWidthRatio] = useState(0) //Calculate the width for the repatition bar for each population

  useEffect(() => {
    async function loadData() {
      if (filterName) {
        setIsLoading(true)

        const response: any = await store.dispatch(
          fetchNpsHeatmap(props.filter.dashboard, props.questionAid, filterName, getHeatmapCeils(filterName))
        )

        if (response.error) {
          setRows([])
        } else {
          //Get max value for the total to determine the ratio for the width
          setRepartitionWidthRatio(response.length > 0 ? (100 / Math.max(...response.map((x) => x.total))) * 2.2 : 0)

          //Update name for default filter
          response.forEach((row) => {
            if (filterName === "birth_date" || filterName === "company_welcome_date") {
              const item = getUserFilterDates(
                filterName === "birth_date" ? "BIRTH_DATE" : "COMPANY_WELCOME_DATE",
                props.survey.active.dateStart
              ).find((x) => x.id === row.name)
              row.name = item ? item.name : ""
            } else if (filterName === "gender") {
              row.name = t("user_gender_" + row.name)
            }
          })

          setRows(response)
        }
      }

      setIsLoading(false)
    }

    loadData()
  }, [filterName])

  function getRepartitionStyle(note: number, total: number) {
    let backgroundColor = "#eb5a46"
    if (note >= 8) {
      backgroundColor = "#20CA7E"
    } else if (note >= 6) {
      backgroundColor = "#f2d600"
    }

    return {
      height: 8,
      width: total * repartitionWidthRatio,
      backgroundColor,
      marginRight: 2,
      borderRadius: 4
    }
  }

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      <div className="height-40" />

      <ListDropdown
        active={filterName}
        isBold
        isCaretAlwaysVisible
        onSelect={(e: any) => setFilterName(e.default ? e.id : e.name)}
        value={filterName ? t("nps_heatmap", { name: getFilterName(filterName) }) : null}
        values={initFilters().filter((x) => x.id !== "company")}
      />

      <div className="height-20" />

      <div style={{ margin: "0 -30px" }}>
        {rows.map((row, i) => (
          <ListItem key={i} isSmall>
            <div style={{ width: 220 }}>{row.name}</div>
            <div className="width-10" />
            <div className="grey-t" style={{ width: 60 }}>
              <FontAwesomeIcon icon={faUser} />
              &nbsp;
              {row.total}
            </div>
            <div className="width-10" />
            <DashboardNpsNoteWidget npsNote={row.nps} fontSize={16} isSmall />
            <Space />
            <div style={{ width: 260 }} className="flex flex-auto">
              {row.repartition.map((j, k) => (
                <Tooltip
                  text={t("nps_tooltip_" + (j > 1 ? "multiple" : "unique"), { count: j, note: k + 1 })}
                  position="top"
                  key={k}>
                  {j > 0 ? (
                    <div className="_hover" style={getRepartitionStyle(k, j)}></div>
                  ) : (
                    <div
                      className="medgrey _hover"
                      style={{ width: 4, height: 4, borderRadius: "50%", marginTop: 2, marginRight: 2 }}
                      key={k}></div>
                  )}
                </Tooltip>
              ))}
            </div>
          </ListItem>
        ))}
      </div>
    </PageLoader>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardNpsHeatmapWidget))
