/**
 * REPORT-NAV.WIDGET.TSX
 * Navigation bar on top for report editor
 */

import { store } from "@/index"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types"
import {
  pageActivate,
  pageDestroy,
  pageDestroy_AsSupervisor,
  pageGet,
  pageRemove,
  pageReplaceTag
} from "@/redux/page.actions"
import { Page, PageState } from "@/redux/page.types"
import { reportEdit, reportStatus, reportUpdate, reportUpdate_AsSupervisor } from "@/redux/report.actions"
import { ReportState } from "@/redux/report.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import ReportAddPageButtonWidget from "./report-add-page-button.widget"
import ReportPagePreviewWidget from "./report-page-preview.widget"

interface StateProps {
  _session: Session
  page: PageState
  report: ReportState
}

function ReportNavWidget(props: StateProps) {
  //Change the order when drag is over
  async function onDragEnd(result: any) {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const movingElement: Page | undefined = props.page.list.find((x: Page) => x.id === draggableId)
    const newPagesOrder: Page[] = props.page.list

    if (movingElement) {
      newPagesOrder.splice(source.index, 1)
      newPagesOrder.splice(destination.index, 0, movingElement)

      store.dispatch(
        reportEdit(
          "pagesOrder",
          newPagesOrder.map((x: Page) => x.id)
        )
      )
      store.dispatch(pageGet(newPagesOrder))

      store.dispatch(reportStatus(STATUS_SAVING))
      const updateFunction = props._session.interfaceType === "SUPERVISOR" ? reportUpdate_AsSupervisor : reportUpdate
      await store.dispatch(updateFunction(props.report.active, []))
      store.dispatch(reportStatus(STATUS_SAVED))
    }
  }

  //Remove page
  async function removePage(pageId: string) {
    if (props.page.active.id === pageId) {
      store.dispatch(pageActivate(props.page.list[0]))
    }

    store.dispatch(pageRemove(pageId))
    store.dispatch(reportStatus(STATUS_SAVING))
    const destroyFunction = props._session.interfaceType === "SUPERVISOR" ? pageDestroy_AsSupervisor : pageDestroy
    await store.dispatch(destroyFunction(pageId))
    store.dispatch(reportStatus(STATUS_SAVED))
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="report-pages-drop" direction="horizontal">
        {(provided: any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              display: props.report.active.navbarOpen ? undefined : "none"
            }}
            className={"flex report-nav" + (props.report.active.paramsOpen ? "" : " report-nav-params-closed")}>
            {props.page.list.map((page: Page, index: number) => (
              <div key={page.id} className="rel flex">
                <Draggable draggableId={page.id} index={index}>
                  {(provided: any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={
                        "report-nav-page grey-b _hover" +
                        (page.id === props.page.active.id ? " report-nav-page-active" : "")
                      }
                      onClick={() => store.dispatch(pageActivate(page))}>
                      <ReportPagePreviewWidget currentPage={page} isSmall />

                      {/*page.count*/}

                      <div className="report-nav-page-title grey-t">
                        {index + 1}. {pageReplaceTag(page.name)}
                      </div>
                    </div>
                  )}
                </Draggable>

                {index < props.page.list.length - 1 && (
                  <div className="report-nav-page-add medgrey-t flex">
                    <div className="report-nav-page-add-content flex">
                      <ReportAddPageButtonWidget pageIndex={index} />
                    </div>
                  </div>
                )}

                <div className="report-nav-page-delete _hover abs flex" onClick={() => removePage(page.id)}>
                  <FontAwesomeIcon icon={faTimes} className="flex-auto" />
                </div>
              </div>
            ))}

            <div className="medgrey-t flex" style={{ fontSize: 40, margin: 20, paddingRight: 80 }}>
              <ReportAddPageButtonWidget pageIndex={props.page.list.length} />
            </div>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  page: state.page,
  report: state.report
})

export default connect(mapStateToProps)(ReportNavWidget)
