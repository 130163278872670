/**
 * REPORT-SCREENSHOT-NPS.WIDGET.TSX
 * Preview of the slide for the NPS widget
 */
import Space from "@/components/space"
import DashboardNpsWidget from "./dashboard-nps.widget"

interface OwnProps {
  isFullWidth?: boolean
}

function ReportScreenshotNpsWidget(props: OwnProps) {
  return (
    <div
      className="flex"
      style={{
        transform: "scale(0.75)",
        transformOrigin: "top left",
        marginTop: -20
      }}>
      <Space />
      <DashboardNpsWidget isOverview isSmall={!props.isFullWidth} />
      <Space />
    </div>
  )
}

export default ReportScreenshotNpsWidget
