/**
 * NPS.ACTIONS
 * Route for net promoter score calculation
 */

import { DashboardFilters } from "./filter.types"

export const fetchNps = (dashboardFilters: DashboardFilters) => ({
  type: "ARCHIVE",
  payload: {
    url: "/nps",
    dashboardFilters
  }
})

export const fetchNpsHeatmap = (
  dashboardFilters: DashboardFilters,
  questionAid: number,
  filterName: string,
  dateCeils: string
) => ({
  type: "ARCHIVE",
  payload: {
    url: "/nps/heatmap",
    dashboardFilters,
    data: {
      questionAid,
      filterName,
      dateCeils
    }
  }
})
