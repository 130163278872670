/**
 * STATUS.TYPES
 * Loading status
 */

//Status type
export const STATUS_LOADING = "STATUS_LOADING"
export const STATUS_LOAD_ERROR = "STATUS_LOAD_ERROR"
export const STATUS_LOADED = "STATUS_LOADED"
export const STATUS_SAVING = "STATUS_SAVING"
export const STATUS_SAVE_ERROR = "STATUS_SAVE_ERROR"
export const STATUS_SAVED = "STATUS_SAVED"
export const STATUS_SEARCHING = "STATUS_SEARCHING"

export type StatusType =
  | "STATUS_LOADING"
  | "STATUS_LOAD_ERROR"
  | "STATUS_LOADED"
  | "STATUS_SAVING"
  | "STATUS_SAVE_ERROR"
  | "STATUS_SAVED"

//Special case : not enough participation for survey
export const INSUFFICIENT_PARTICIPATION = "insufficient_participation"
