/**
 * SUPERVISORS-BENCHMARK.WIDGET
 * Update benchmark from widget
 */

import Space from "@/components/space"
import Card from "@/components/card"
import { useEffect, useState } from "react"
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVING } from "@/redux/_status.types"
import ListFields from "@/components/list-fields"
import ListCell from "@/components/list-cell"
import { store } from ".."
import {
  benchmarkCalculateNps_AsSupervisor,
  benchmarkFetchByAccount_AsSupervisor,
  benchmarkUpdateAccount_AsSupervisor
} from "@/redux/benchmark.actions"
import ListItem from "@/components/list-item"
import DashboardNpsNoteWidget from "./dashboard-nps-note.widget"
import ListDropdown from "@/components/list-dropdown"
import { BENCHMARK_SECTOR_LIST } from "@/redux/benchmark.types"
import i18n from "@/translate/i18n"
import Chip from "@/components/chip"
import { faInfoCircle, faTimes, faUser } from "@fortawesome/free-solid-svg-icons"
import SaveIcon from "@/components/save-icon"
import Tooltip from "@/components/tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { sortBy } from "lodash"
import ListInput from "@/components/list-input"
import Button from "@/components/button"
import LoadingModal from "@/modals/loading.modal"

const MODAL_CALCULATE = "MODAL_CALCULATE"

function SupervisorsBenchmarkWidget() {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const [savingAccountId, setSavingAccountId] = useState<string | null>(null)
  const [accounts, setAccounts] = useState<any[]>([])
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  useEffect(() => {
    loadBenchmark()

    async function loadBenchmark() {
      //Fetch data
      const response: any = await store.dispatch(benchmarkFetchByAccount_AsSupervisor())

      //Sort by sector
      //> first null values
      //> then by order
      setAccounts(
        response.error
          ? []
          : response
              .filter((x) => !x.sector)
              .concat(
                sortBy(
                  response.filter((x) => x.sector),
                  "sector"
                )
              )
      )

      setIsLoaded(true)
    }
  }, [])

  async function calculate() {
    setCurrentModal(MODAL_CALCULATE)
    await store.dispatch(benchmarkCalculateNps_AsSupervisor())
    setCurrentModal(null)
  }

  async function editAccount(id: string, key, value: string) {
    setIsEdited(true)
    setSavingAccountId(id)
    const response: any = await store.dispatch(benchmarkUpdateAccount_AsSupervisor(id, key, value))
    setSavingAccountId(null)
    if (!response.error) {
      setAccounts(accounts.map((x) => (x.id === id ? Object.assign({}, x, { [key]: value }) : x)))
    }
  }

  return (
    <Card isWithoutPadding status={isLoaded ? STATUS_LOADED : STATUS_LOADING}>
      <ListFields>
        <ListCell width={240} text="Compte" />
        <Space />
        <ListCell width={200} text="Secteur" />
        <ListCell />
        <ListCell width={280} text="Taille" />
        <ListCell width={120} text="eNPS" />
        <ListCell width={68} />
      </ListFields>

      {currentModal === MODAL_CALCULATE && <LoadingModal />}

      {isEdited && (
        <div
          style={{
            zIndex: 1,
            bottom: 10,
            position: "fixed",
            right: 20
          }}>
          <Button className="primary" onClick={() => calculate()} isLarge>
            Calculer eNPS
          </Button>
        </div>
      )}

      {accounts.map((account, i) => (
        <ListItem key={i}>
          <ListCell width={240}>{account.account}</ListCell>
          <Space />
          {!account.sector && <Chip icon={faTimes} color="#eb5a46" />}
          <div className="width-20" />
          <ListCell width={200}>
            <ListDropdown
              isNullAllowed
              value={account.sector ? i18n.t("benchmark_sector_" + account.sector) : null}
              values={BENCHMARK_SECTOR_LIST.map((x) => {
                return { id: x, name: i18n.t("benchmark_sector_" + x) }
              })}
              onSelect={(e) => editAccount(account.id, "sector", e.id)}
            />
          </ListCell>
          <ListCell>
            {account.sector && (
              <Tooltip text={i18n.t("benchmark_sector_" + account.sector + "_help")}>
                <FontAwesomeIcon icon={faInfoCircle} className="grey-t _hover" />
              </Tooltip>
            )}
          </ListCell>
          <ListCell width={280}>
            <Chip icon={faUser} color={account.size ? "#ff9500" : null}>
              {account.size ? account.size : account.participation}
            </Chip>
            <div className="width-10" />
            <ListInput
              value={account.size ? account.size : account.participation}
              onEditEnd={(e) => editAccount(account.id, "size", e === "" ? null : e)}
              type="number"
            />
          </ListCell>
          <ListCell width={120}>
            <DashboardNpsNoteWidget npsNote={Number(account.enps)} fontSize={16} isSmall />
          </ListCell>
          <SaveIcon status={account.id === savingAccountId ? STATUS_SAVING : null} />
        </ListItem>
      ))}

      <div className="height-60" />
    </Card>
  )
}

export default SupervisorsBenchmarkWidget
